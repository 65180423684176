import {getMoneyTypes, getPieceTypes, getVolumeTypes, getWeightTypes} from '@/libs/constants/ingredients'
import {PackagingUnit} from '@/libs/enums/PackagingUnit'

const volume_types = getVolumeTypes()
const weight_types = getWeightTypes()
const piece_types = getPieceTypes()
const money_types = getMoneyTypes()

export const getType = (item) => {
    let type = null
    if (item.packaging_unit === PackagingUnit.Milliliter || item.packaging_unit === PackagingUnit.Decilitre || item.packaging_unit === PackagingUnit.Liter) {
        type = volume_types.find(ele => ele.name === item.packaging_unit)
    } else if (item.packaging_unit === PackagingUnit.Gram || item.packaging_unit === PackagingUnit.Decagram || item.packaging_unit === PackagingUnit.Kilogram) {
        type = weight_types.find(ele => ele.name === item.packaging_unit)
    } else if (item.packaging_unit === PackagingUnit.Euro) {
        type = money_types.find(ele => ele.name === item.packaging_unit)
    } else {
        type = piece_types.find(ele => ele.name === item.packaging_unit)
    }

    return type
}

export const getTypeUnit = (unit) => {
    const item = {}
    item.packaging_unit = unit
    return getType(item)
}

export const getPackagingValues = (item) => {
    if (!item) return '/'

    const type = getType(item)

    if (!type) {
        return
    }

    const allowedDecimals = Math.log10(type.factor)
    const packaging = Number(Number(item.packaging) / Number(type.factor)).toFixed(allowedDecimals).replace(`.${'0'.repeat(allowedDecimals)}`, '')

    return `${packaging} ${item.packaging_unit}`
}

export const convertToDefaultStock = (packaging, unit) => {
    const type = getTypeUnit(unit)
    if (!type) {
        return 0
    }

    const stock = Number(Number(packaging) * Number(type.factor)).toFixed(2).replace('.00', '')

    return Number(stock)
}

export const getPackaging = (params) => {
    let factor = 1
    const  type = getType(params)
    if (!type) {
        return
    }

    factor = type.factor

    const packagingInDisplayedUnit = params.packaging / factor
    return packagingInDisplayedUnit + params.packaging_unit
}

export const getStockNumber = (stock, unit) => {
    const type = getTypeUnit(unit)

    if (!type) {
        return
    }

    const allowedDecimals = Math.log10(type.factor)

    return Number(Number(stock) / Number(type.factor)).toFixed(allowedDecimals)
}
export const getStock = (stock, unit) => {
    const stockOut = getStockNumber(stock, unit)

    return `${stockOut} ${unit}`
}

export const compareItemsArray = (arr1, arr2) => {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false
    if (arr1.length !== arr2.length) return false
    return arr1.every((item, index) => item === arr2[index])
}
